@import '../../../assets/global-style/mixin';

.link {
    font-family: var(--font-primery);
    font-size: 14px;
    line-height: 140%;
    color: var(--color-blue);
    text-decoration: none;
    border-bottom: 1px solid var(--color-blue);
    transition: 0.5s;
    cursor: pointer;

    @include before_768 {
        font-size: 12px;
    }

    &:hover {
        border-bottom: 1px solid transparent;
    }

    &--dash {
        border-bottom: 1px dashed var(--color-blue);

        &:hover {
            border-bottom: 1px dashed transparent;
        }
    }

    &--fs16 {
        font-size: 16px;
    }
}
