@import '../../../assets/global-style/mixin';

.why {
    margin-bottom: 48px;

    &__flex {
        display: flex;
        align-items: flex-start;

        @include before_1024 {
            flex-direction: column;
            align-items: center;
        }
    }

    &__el {
        &:first-child {
            flex-grow: 1;
        }

        &:last-child {
            flex-shrink: 0;

            @include before_768 {
                flex-shrink: 1;
            }
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 48px;

        @include before_768 {
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
        }
    }

    &__block {
        position: relative;
        margin-bottom: 32px;
        padding-left: 20px;

        @include before_480 {
            margin-bottom: 16px;
            padding-left: 10px;
        }
    }

    &__number {
        font-style: italic;
        font-family: var(--font-secondary);
        font-weight: 500;
        font-size: 80px;
        line-height: 96px;
        color: var(--color-gray);

        @include before_480 {
            font-size: 40px;
            line-height: 48px;
        }
    }

    &__icon {
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-20%, 30%);

        @include before_480 {
            transform: translate(-10%, 40%);
            width: 32px;
            height: 32;
        }
    }

    &__description {
        font-size: 16px;

        @include before_480 {
            font-size: 12px;
        }
    }
}
