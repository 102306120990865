.about {
    margin-bottom: 48px;

    &__flex {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;

        @include before_768 {
            grid-template-columns: 1fr;
            gap: 24px;
        }
    }

    &__text {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}
