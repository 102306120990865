.price {
    margin-bottom: 48px;

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        @include before_768 {
            grid-template-columns: 1fr;
            gap: 12px;
        }
    }

    &__item {
        position: relative;
        padding: 6px;
        border-radius: 12px;
        box-shadow: 0px 5px 26px rgba(159, 165, 175, 0.25);
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: 80px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 8px;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 36px 26px 18px;
    }

    &__name {
        margin-bottom: 16px;
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 20px;
        color: var(--text-primary-color);
    }

    &__value {
        margin-bottom: 24px;
        font-size: 24px;
        color: var(--text-primary-color);

        sup {
            text-decoration: line-through;
            font-size: 14px;
            padding-left: 12px;
        }
    }

    &__button {
        margin-top: auto;
    }

    &__discount {
        position: absolute;
        top: 25px;
        left: 0;
        width: 80%;
        padding: 12px 20px;
        background: linear-gradient(180deg, #88b8ff 0%, #5695f2 100%), #d9d9d9;
        border-radius: 0px 8px 8px 0px;

        @include before_1200 {
            width: 100%;
        }

        @include before_768 {
            width: 80%;
        }

        @include before_480 {
            padding: 8px 12px;
        }

        &-flex {
            display: flex;
            align-items: center;
        }

        &-icon {
            flex-shrink: 0;
            margin-right: 8px;
        }

        &-text {
            font-weight: 500;
            font-size: 16px;
            color: var(--color-white);

            @include before_480 {
                font-size: 14px;
            }
        }
    }
}
