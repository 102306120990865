@import '../../../assets/global-style/mixin';

.img-box {
    position: relative;
    padding-bottom: 56.25%;

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &--price {
        padding-bottom: 66.85%;

        .img-box__img {
            border-radius: 12px;
            object-fit: contain;
        }
    }

    &--application {
        padding-bottom: 90%;

        .img-box__img {
            border-radius: 12px;
            // object-fit: contain;
        }
    }
}
