.retiree {
    position: relative;
    max-width: 1920px;
    margin: 0 auto 96px;

    @include before_1024 {
        padding-bottom: 100px;
    }

    @include before_480 {
        padding-bottom: 130px;
    }

    &__content {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        width: 100%;
        max-width: 1920px;
        padding-top: 200px;

        @include before_1200 {
            padding-top: 100px;
        }

        @include before_1024 {
            top: auto;
            bottom: -100px;
            padding-top: 0;
        }
    }

    &__img-box {
        @include before_1024 {
            display: flex;
            justify-content: center;
        }
    }

    &__bg-img {
        @include before_1024 {
            display: none;
        }
    }

    &__bg-mobile {
        display: none;

        @include before_1024 {
            display: block;
        }
    }

    &__discount-img {
        cursor: pointer;
    }
}
