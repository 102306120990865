.footer {
    padding: 32px 0;
    border-top: 1px solid rgba(159, 165, 175, 0.16);

    &__flex {
        display: flex;
        justify-content: space-between;

        @include before_1024 {
            flex-direction: column-reverse;
            align-items: center;
        }
    }

    &__right {
        display: flex;
        align-items: center;

        @include before_1024 {
            margin-bottom: 12px;
        }

        @include before_768 {
            flex-direction: column;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 140%;
        color: var(--color-gray-2);
    }

    &__email {
        margin-right: 40px;
        font-size: 16px;
        line-height: 120%;
        color: var(--color-black);
        text-decoration: none;
        transition: 0.5s;

        @include before_768 {
            margin-right: 0;
            margin-bottom: 12px;
        }

        &:hover {
            opacity: 0.6;
        }
    }

    &__phone {
        font-weight: 700;
        color: var(--color-black);
        text-decoration: none;
        transition: 0.5s;
        // text-align: center;

        &:hover {
            opacity: 0.6;
        }

        &--mr40 {
            margin-right: 40px;

            @include before_768 {
                margin-right: 0;
                margin-bottom: 12px;
            }
        }
    }

    &__social {
        display: flex;
        align-items: center;
    }

    &__icon {
        margin-right: 12px;
    }

    &__address {
        margin-bottom: 12px;
        color: var(--color-black);
    }
}
