@import '../../../assets/global-style/mixin';

.menu {
    @include before_768 {
        position: fixed;
        // display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 50px);
        padding: 30px 15px;
        background-color: var(--color-white);
        transition: 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
        z-index: 100;
        transform: translate(-50px, 0);
    }

    &.js-menu-active {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
    }

    &__flex {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include before_768 {
            flex-direction: column;
            align-items: center;
        }
    }

    &__list {
        display: flex;
        margin-right: 12px;

        @include before_1024 {
            flex-direction: column;
            align-items: flex-start;
        }

        @include before_768 {
            margin-right: 0;
            margin-bottom: 24px;
        }
    }

    &__item {
        position: relative;

        @include before_768 {
            padding-bottom: 0;
        }

        &:not(:last-child) {
            margin-right: 40px;

            @include before_1200 {
                margin-right: 24px;
            }

            @include before_768 {
                margin-right: 0;
                margin-bottom: 12px;
            }
        }
    }

    &__link {
        font-family: var(--font-primery);
        font-size: 18px;
        line-height: 22px;
        color: var(--color-black);
        text-decoration: none;
        transition: opacity 0.5s;

        &:hover {
            opacity: 0.6;
        }
    }

    &__time-email {
        display: flex;

        @include before_1200 {
            flex-direction: column;
        }

        @include before_768 {
            margin-bottom: 24px;
        }
    }

    &__time-block {
        position: relative;
        max-width: 110px;
        margin-right: 24px;
        font-size: 14px;
        line-height: 120%;
        color: var(--color-gray-2);

        @include before_768 {
            margin-right: 0;
            margin-bottom: 24px;
        }

        @include before_1200 {
            margin-right: 0;
            max-width: none;
        }

        &::before {
            content: '';
            position: absolute;
            left: -13px;
            top: 8px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: var(--color-blue);
        }
    }

    &__email {
        font-size: 16px;
        line-height: 140%;
        color: var(--color-black);
        text-decoration: none;
        transition: 0.5s;

        &:hover {
            opacity: 0.6;
        }
    }

    &__phone-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__phone {
        font-weight: 700;
        color: var(--color-black);
        text-decoration: none;
    }

    &__closed {
        position: absolute;
        right: 20px;
        top: 20px;
        display: none;
        cursor: pointer;

        @include before_768 {
            display: block;
        }
    }

    // &__phone-link {
    //     font-size: 14px;
    //     color: var(--color-blue);
    //     text-decoration: none;
    //     border-bottom: 1px dashed var(--color-blue);
    //     transition: 0.5s;

    //     &:hover {
    //         border-bottom: 1px dashed transparent;
    //     }
    // }
}
