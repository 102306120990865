@import '../../../assets/global-style/mixin';

.checkbox {
    &--margin {
        margin-bottom: 24px;
    }

    &__item {
        display: flex;
        flex-shrink: 0;
        align-items: center;

        &--half {
            width: 50%;
            margin-bottom: 30px;
        }
    }

    &__window {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        border: 1px solid var(--color-gray-3);
        border-radius: 4px;
    }

    &__icon-mark {
        fill: var(--color-white);
        opacity: 0;
        transition: 0.3s;
    }

    &__name {
        display: flex;
        align-items: flex-start;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        color: var(--text-primery-color);
        cursor: pointer;
    }

    &__input:checked + .checkbox__name {
        // .checkbox__window {
        //     background: var(--color-blue);
        // }
        .checkbox__icon-mark {
            opacity: 1;
        }
    }

    &__link {
        color: var(--color-blue);
        text-decoration: none;
        border-bottom: 1px solid var(--color-blue);
        transition: 0.5s;

        &:hover {
            border-bottom: 1px solid transparent;
        }
    }

    &__text {
        font-size: 14px;
        color: var(--color-black);

        &--margin {
            margin-right: 5px;
        }
    }

    &__error-message {
        display: none;
        margin-top: 5px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.04em;
        color: var(--error-primery-color);
    }

    &.js-checkbox-hidden {
        display: none;
    }
}
