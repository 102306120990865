@import '../../../assets/global-style/mixin';

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 18px 32px;

    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-decoration: none;
    text-align: center;

    background: var(--gradient-secondary);
    border: none;
    border-radius: 8px;

    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    transition: 0.5s;
    webkit-appearance: none; //убирает дефолтные стили в ios

    &:hover {
        opacity: 0.6;
    }

    &:active {
        opacity: 0.5;
        transition: 0.1s;
    }

    &:focus {
        opacity: 0.9;
        transition: 0.1s;
    }

    &.disabled {
        opacity: 0.8;
        pointer-events: none;
    }

    @include before_768 {
        padding: 12px 16px;
        font-size: 14px;
        line-height: 16px;
    }
}
