.certificates {
    margin-bottom: 48px;

    &__list {
        display: flex;
        gap: 38px;

        @include before_768 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;
        }

        @include before_768 {
            display: grid;
            grid-template-columns: 1fr;
            gap: 24px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 23%;
        padding: 8px;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.06);

        @include before_768 {
            width: auto;
        }
    }
}
