.application {
    margin-bottom: 64px;

    &__content {
        padding: 6px;
        box-shadow: 0px 8px 24px rgba(159, 165, 175, 0.25);
        border-radius: 12px;
    }

    &__flex {
        display: flex;
        gap: 12px;
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 8px;
    }

    &__el {
        &--img {
            position: relative;
            flex-shrink: 0;
            width: 50%;

            @include before_1024 {
                display: none;
            }
        }

        &--form {
            flex-grow: 1;
            padding-top: 4px;
            padding-right: 34px;
            padding-bottom: 24px;

            @include before_1024 {
                padding-left: 34px;
            }

            @include before_480 {
                padding: 12px;
            }
        }
    }

    &__img-worker {
        position: absolute;
        bottom: 0;
        left: 70px;
    }

    &__text {
        margin-bottom: 24px;
    }

    .title {
        margin-bottom: 16px;
    }
}
