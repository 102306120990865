@import '../../../assets/global-style/mixin';
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 50;

    &.is-active {
        opacity: 1;
        visibility: visible;

        .popup__content {
            transform: translate(0, 0);
            opacity: 1;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        padding: 30px 10px;
    }

    &__content {
        position: relative;
        max-width: 560px;
        padding: 40px 48px 48px;
        background: var(--color-white);
        transition: 0.5s;
        transform: translate(0, -100%);
        opacity: 0;

        @include before_768 {
            padding: 20px 24px 24px;
        }
    }

    &__title {
        margin-bottom: 32px;
        font-size: 32px;
        line-height: 45px;
        color: var(--text-primery-color);

        @include before_768 {
            font-size: 22px;
            line-height: 33px;
        }

        &--center {
            text-align: center;
        }
    }

    &__text {
        margin: 0 0 10px;
        font-weight: normal;
        color: var(--color-black);
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;

        &-icon {
            fill: var(--color-gray-2);
            transition: 0.5s;

            &:hover {
                fill: var(--color-black);
            }
        }
    }
}
