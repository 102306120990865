@import '../../../assets/global-style/mixin';

.order {
    margin-bottom: 80px;

    .title {
        margin-bottom: 24px;
    }

    &__text {
        margin-bottom: 48px;
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 48px;

        @include before_768 {
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
        }
    }

    &__icon {
        margin-bottom: 24px;
    }

    &__description {
        @include before_480 {
            font-size: 14px;
        }
    }
}
