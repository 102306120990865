.link-icon {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.link-icon__text {
  font-family: "MuseoSansCyrl", "Arial", sans-serif;
  margin-right: 11px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: var(--color-blue);
}
.link-icon__svg {
  flex-shrink: 0;
  fill: var(--color-blue);
}
.link-icon__svg--red {
  fill: #e7413a;
}

.link {
  font-family: var(--font-primery);
  font-size: 14px;
  line-height: 140%;
  color: var(--color-blue);
  text-decoration: none;
  border-bottom: 1px solid var(--color-blue);
  transition: 0.5s;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .link {
    font-size: 12px;
  }
}
.link:hover {
  border-bottom: 1px solid transparent;
}
.link--dash {
  border-bottom: 1px dashed var(--color-blue);
}
.link--dash:hover {
  border-bottom: 1px dashed transparent;
}
.link--fs16 {
  font-size: 16px;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 18px 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  text-decoration: none;
  text-align: center;
  background: var(--gradient-secondary);
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  transition: 0.5s;
  webkit-appearance: none;
}
.button:hover {
  opacity: 0.6;
}
.button:active {
  opacity: 0.5;
  transition: 0.1s;
}
.button:focus {
  opacity: 0.9;
  transition: 0.1s;
}
.button.disabled {
  opacity: 0.8;
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .button {
    padding: 12px 16px;
    font-size: 14px;
    line-height: 16px;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.banner {
  background: var(--banner-gradient);
}
.banner__content {
  max-width: 1920px;
  margin: 0 auto;
  padding-bottom: 80px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 1023px) {
  .banner__content {
    padding-bottom: 40px;
    background-size: cover;
  }
}
@media screen and (max-width: 767px) {
  .banner__content {
    padding-bottom: 16px;
    background-size: cover;
  }
}
.banner__title {
  max-width: 460px;
  font-family: var(--font-secondary);
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 64px;
  line-height: 70px;
}
@media screen and (max-width: 1023px) {
  .banner__title {
    max-width: 240px;
    font-size: 32px;
    line-height: 35px;
  }
}
@media screen and (max-width: 479px) {
  .banner__title {
    max-width: 190px;
    font-size: 32px;
    line-height: 35px;
  }
}
.banner__description {
  margin-bottom: 32px;
  font-family: var(--font-secondary);
  font-weight: 300;
  font-size: 36px;
  line-height: 43px;
}
@media screen and (max-width: 1023px) {
  .banner__description {
    font-size: 18px;
    line-height: 22px;
  }
}
@media screen and (max-width: 479px) {
  .banner__description {
    max-width: 150px;
  }
}
.banner__button {
  margin-bottom: 24px;
}
@media screen and (max-width: 767px) {
  .banner__button {
    margin-bottom: 8px;
  }
}
.banner__order {
  position: relative;
  display: flex;
  margin-bottom: 157px;
  padding-left: 80px;
}
@media screen and (max-width: 1023px) {
  .banner__order {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 767px) {
  .banner__order {
    margin-bottom: 24px;
    padding-left: 40px;
  }
}
.banner__arrow-up {
  align-self: flex-start;
  margin-right: 12px;
}
@media screen and (max-width: 767px) {
  .banner__arrow-up {
    width: 16px;
    height: auto;
  }
}
.banner__order-text {
  padding-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: var(--color-gray-2);
}
@media screen and (max-width: 767px) {
  .banner__order-text {
    padding-top: 4px;
    font-size: 12px;
  }
}
.banner__accreditation {
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
  color: var(--color-blue);
}
@media screen and (max-width: 767px) {
  .banner__accreditation {
    font-size: 12px;
  }
}
@media screen and (max-width: 479px) {
  .banner__blue {
    color: var(--color-blue);
  }
}
.banner__metrogroup {
  display: block;
  font-weight: bold;
  font-size: 45px;
  line-height: 140%;
}
@media screen and (max-width: 479px) {
  .banner__metrogroup {
    font-size: 24px;
  }
}

.header {
  padding: 12px 0;
  margin-bottom: 180px;
}
@media screen and (max-width: 1023px) {
  .header {
    margin-bottom: 90px;
  }
}
@media screen and (max-width: 767px) {
  .header {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 50px);
    padding: 30px 15px;
    background-color: var(--color-white);
    transition: 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
    transform: translate(-50px, 0);
  }
}
.menu.js-menu-active {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}
.menu__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .menu__flex {
    flex-direction: column;
    align-items: center;
  }
}
.menu__list {
  display: flex;
  margin-right: 12px;
}
@media screen and (max-width: 1023px) {
  .menu__list {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media screen and (max-width: 767px) {
  .menu__list {
    margin-right: 0;
    margin-bottom: 24px;
  }
}
.menu__item {
  position: relative;
}
@media screen and (max-width: 767px) {
  .menu__item {
    padding-bottom: 0;
  }
}
.menu__item:not(:last-child) {
  margin-right: 40px;
}
@media screen and (max-width: 1199px) {
  .menu__item:not(:last-child) {
    margin-right: 24px;
  }
}
@media screen and (max-width: 767px) {
  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
.menu__link {
  font-family: var(--font-primery);
  font-size: 18px;
  line-height: 22px;
  color: var(--color-black);
  text-decoration: none;
  transition: opacity 0.5s;
}
.menu__link:hover {
  opacity: 0.6;
}
.menu__time-email {
  display: flex;
}
@media screen and (max-width: 1199px) {
  .menu__time-email {
    flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .menu__time-email {
    margin-bottom: 24px;
  }
}
.menu__time-block {
  position: relative;
  max-width: 110px;
  margin-right: 24px;
  font-size: 14px;
  line-height: 120%;
  color: var(--color-gray-2);
}
@media screen and (max-width: 767px) {
  .menu__time-block {
    margin-right: 0;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 1199px) {
  .menu__time-block {
    margin-right: 0;
    max-width: none;
  }
}
.menu__time-block::before {
  content: "";
  position: absolute;
  left: -13px;
  top: 8px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--color-blue);
}
.menu__email {
  font-size: 16px;
  line-height: 140%;
  color: var(--color-black);
  text-decoration: none;
  transition: 0.5s;
}
.menu__email:hover {
  opacity: 0.6;
}
.menu__phone-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.menu__phone {
  font-weight: 700;
  color: var(--color-black);
  text-decoration: none;
}
.menu__closed {
  position: absolute;
  right: 20px;
  top: 20px;
  display: none;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .menu__closed {
    display: block;
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.why {
  margin-bottom: 48px;
}
.why__flex {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 1023px) {
  .why__flex {
    flex-direction: column;
    align-items: center;
  }
}
.why__el:first-child {
  flex-grow: 1;
}
.why__el:last-child {
  flex-shrink: 0;
}
@media screen and (max-width: 767px) {
  .why__el:last-child {
    flex-shrink: 1;
  }
}
.why__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px;
}
@media screen and (max-width: 767px) {
  .why__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
}
.why__block {
  position: relative;
  margin-bottom: 32px;
  padding-left: 20px;
}
@media screen and (max-width: 479px) {
  .why__block {
    margin-bottom: 16px;
    padding-left: 10px;
  }
}
.why__number {
  font-style: italic;
  font-family: var(--font-secondary);
  font-weight: 500;
  font-size: 80px;
  line-height: 96px;
  color: var(--color-gray);
}
@media screen and (max-width: 479px) {
  .why__number {
    font-size: 40px;
    line-height: 48px;
  }
}
.why__icon {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-20%, 30%);
}
@media screen and (max-width: 479px) {
  .why__icon {
    transform: translate(-10%, 40%);
    width: 32px;
    height: 32;
  }
}
.why__description {
  font-size: 16px;
}
@media screen and (max-width: 479px) {
  .why__description {
    font-size: 12px;
  }
}

.order {
  margin-bottom: 80px;
}
.order .title {
  margin-bottom: 24px;
}
.order__text {
  margin-bottom: 48px;
}
.order__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 48px;
}
@media screen and (max-width: 767px) {
  .order__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
}
.order__icon {
  margin-bottom: 24px;
}
@media screen and (max-width: 479px) {
  .order__description {
    font-size: 14px;
  }
}

.wrapper {
  width: 100%;
  max-width: 1250px;
  padding: 0 50px;
  margin: 0 auto;
  box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .wrapper {
    padding: 0 20px;
  }
}

.calculator {
  margin-bottom: 64px;
}
.calculator__flex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 40px;
}
@media screen and (max-width: 1023px) {
  .calculator__flex {
    grid-template-columns: 80%;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .calculator__flex {
    grid-template-columns: 1fr;
  }
}
.calculator__el {
  padding: 6px;
  border-radius: 12px;
  background: var(--gradient-secondary);
  box-shadow: 0px 8px 40px rgba(62, 63, 83, 0.25);
}
.calculator__content {
  height: 100%;
  padding: 18px 26px 34px;
  border: 1px solid var(--color-white-rgba40);
  border-radius: 6px;
}
.calculator__el-title {
  margin-bottom: 16px;
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--color-white);
}
.calculator__el-text {
  max-width: 350px;
  margin-bottom: 24px;
  color: var(--color-white);
}
.calculator__list {
  display: flex;
  gap: 35px;
}
@media screen and (max-width: 479px) {
  .calculator__list {
    flex-direction: column;
  }
}
.calculator__water {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.calculator__icon {
  margin-right: 16px;
}
.calculator__water-name {
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 20px;
  color: var(--color-white);
}
.calculator__price {
  padding-left: 30px;
  font-size: 24px;
  color: var(--color-white);
}
@media screen and (max-width: 1199px) {
  .calculator__price {
    font-size: 20px;
  }
}
.calculator__note {
  color: var(--color-gray-2);
}
.calculator__note--mb40 {
  margin-bottom: 40px;
}
.calculator__result {
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: var(--color-blue);
  text-align: center;
}
@media screen and (max-width: 479px) {
  .calculator__result {
    font-size: 24px;
    line-height: 32px;
  }
}

.application {
  margin-bottom: 64px;
}
.application__content {
  padding: 6px;
  box-shadow: 0px 8px 24px rgba(159, 165, 175, 0.25);
  border-radius: 12px;
}
.application__flex {
  display: flex;
  gap: 12px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.application__el--img {
  position: relative;
  flex-shrink: 0;
  width: 50%;
}
@media screen and (max-width: 1023px) {
  .application__el--img {
    display: none;
  }
}
.application__el--form {
  flex-grow: 1;
  padding-top: 4px;
  padding-right: 34px;
  padding-bottom: 24px;
}
@media screen and (max-width: 1023px) {
  .application__el--form {
    padding-left: 34px;
  }
}
@media screen and (max-width: 479px) {
  .application__el--form {
    padding: 12px;
  }
}
.application__img-worker {
  position: absolute;
  bottom: 0;
  left: 70px;
}
.application__text {
  margin-bottom: 24px;
}
.application .title {
  margin-bottom: 16px;
}

.about {
  margin-bottom: 48px;
}
.about__flex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
@media screen and (max-width: 767px) {
  .about__flex {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
.about__text:not(:last-child) {
  margin-bottom: 16px;
}

.price {
  margin-bottom: 48px;
}
.price__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
@media screen and (max-width: 767px) {
  .price__list {
    grid-template-columns: 1fr;
    gap: 12px;
  }
}
.price__item {
  position: relative;
  padding: 6px;
  border-radius: 12px;
  box-shadow: 0px 5px 26px rgba(159, 165, 175, 0.25);
}
.price__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 80px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.price__bottom {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 36px 26px 18px;
}
.price__name {
  margin-bottom: 16px;
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 20px;
  color: var(--text-primary-color);
}
.price__value {
  margin-bottom: 24px;
  font-size: 24px;
  color: var(--text-primary-color);
}
.price__value sup {
  text-decoration: line-through;
  font-size: 14px;
  padding-left: 12px;
}
.price__button {
  margin-top: auto;
}
.price__discount {
  position: absolute;
  top: 25px;
  left: 0;
  width: 80%;
  padding: 12px 20px;
  background: linear-gradient(180deg, #88b8ff 0%, #5695f2 100%), #d9d9d9;
  border-radius: 0px 8px 8px 0px;
}
@media screen and (max-width: 1199px) {
  .price__discount {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .price__discount {
    width: 80%;
  }
}
@media screen and (max-width: 479px) {
  .price__discount {
    padding: 8px 12px;
  }
}
.price__discount-flex {
  display: flex;
  align-items: center;
}
.price__discount-icon {
  flex-shrink: 0;
  margin-right: 8px;
}
.price__discount-text {
  font-weight: 500;
  font-size: 16px;
  color: var(--color-white);
}
@media screen and (max-width: 479px) {
  .price__discount-text {
    font-size: 14px;
  }
}

.certificates {
  margin-bottom: 48px;
}
.certificates__list {
  display: flex;
  gap: 38px;
}
@media screen and (max-width: 767px) {
  .certificates__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}
@media screen and (max-width: 767px) {
  .certificates__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
  }
}
.certificates__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 23%;
  padding: 8px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
@media screen and (max-width: 767px) {
  .certificates__item {
    width: auto;
  }
}

.retiree {
  position: relative;
  max-width: 1920px;
  margin: 0 auto 96px;
}
@media screen and (max-width: 1023px) {
  .retiree {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 479px) {
  .retiree {
    padding-bottom: 130px;
  }
}
.retiree__content {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 1920px;
  padding-top: 200px;
}
@media screen and (max-width: 1199px) {
  .retiree__content {
    padding-top: 100px;
  }
}
@media screen and (max-width: 1023px) {
  .retiree__content {
    top: auto;
    bottom: -100px;
    padding-top: 0;
  }
}
@media screen and (max-width: 1023px) {
  .retiree__img-box {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 1023px) {
  .retiree__bg-img {
    display: none;
  }
}
.retiree__bg-mobile {
  display: none;
}
@media screen and (max-width: 1023px) {
  .retiree__bg-mobile {
    display: block;
  }
}
.retiree__discount-img {
  cursor: pointer;
}

.start {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.start__img-box {
  background: linear-gradient(-10deg, #003140 50%, #ffffff 50%);
}
.start__img {
  margin: 0 auto;
}
.start__block {
  flex-grow: 1;
  width: 100%;
  background-color: #003140;
}

.burger {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  flex-shrink: 0;
  cursor: pointer;
}
@media screen and (max-width: 1023px) {
  .burger {
    display: block;
  }
}

.burger__strip,
.burger__strip:before,
.burger__strip:after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -8px;
  width: 16px;
  height: 2px;
  background-color: var(--color-black);
}

.burger__strip:before,
.burger__strip:after {
  content: "";
  transition: 0.3s;
}

.burger__strip:before {
  transform: translateY(-5px);
}

.burger__strip:after {
  transform: translateY(5px);
}

.burger--active .burger__strip {
  height: 0;
}

.burger--active .burger__strip:before {
  transform: rotate(45deg);
}

.burger--active .burger__strip:after {
  transform: rotate(-45deg);
}

.icon {
  fill: var(--color-blue);
}
.icon--black {
  fill: var(--color-black);
}

.img-box {
  position: relative;
  padding-bottom: 56.25%;
}
.img-box__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.img-box--price {
  padding-bottom: 66.85%;
}
.img-box--price .img-box__img {
  border-radius: 12px;
  object-fit: contain;
}
.img-box--application {
  padding-bottom: 90%;
}
.img-box--application .img-box__img {
  border-radius: 12px;
}

.footer {
  padding: 32px 0;
  border-top: 1px solid rgba(159, 165, 175, 0.16);
}
.footer__flex {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1023px) {
  .footer__flex {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.footer__right {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  .footer__right {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 767px) {
  .footer__right {
    flex-direction: column;
  }
}
.footer__text {
  font-size: 16px;
  line-height: 140%;
  color: var(--color-gray-2);
}
.footer__email {
  margin-right: 40px;
  font-size: 16px;
  line-height: 120%;
  color: var(--color-black);
  text-decoration: none;
  transition: 0.5s;
}
@media screen and (max-width: 767px) {
  .footer__email {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
.footer__email:hover {
  opacity: 0.6;
}
.footer__phone {
  font-weight: 700;
  color: var(--color-black);
  text-decoration: none;
  transition: 0.5s;
}
.footer__phone:hover {
  opacity: 0.6;
}
.footer__phone--mr40 {
  margin-right: 40px;
}
@media screen and (max-width: 767px) {
  .footer__phone--mr40 {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
.footer__social {
  display: flex;
  align-items: center;
}
.footer__icon {
  margin-right: 12px;
}
.footer__address {
  margin-bottom: 12px;
  color: var(--color-black);
}

.title {
  margin-bottom: 48px;
  font-family: var(--font-secondary);
  line-height: 140%;
  color: var(--color-black);
}
@media (max-width: 767px) {
  .title {
    font-size: calc(
			20px + 27.2 * ((100vw - 320px) / 1220)
		);
  }
}
@media (min-width: 767px) {
  .title {
    font-size: calc(
			20px + 16 * (100vw / 1220)
		);
  }
}