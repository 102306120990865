@import '../../../assets/global-style/mixin';

.calculator {
    margin-bottom: 64px;

    &__flex {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        margin-bottom: 40px;

        @include before_1024 {
            grid-template-columns: 80%;
            justify-content: center;
        }

        @include before_768 {
            grid-template-columns: 1fr;
        }
    }

    &__el {
        padding: 6px;
        border-radius: 12px;
        background: var(--gradient-secondary);
        box-shadow: 0px 8px 40px rgba(62, 63, 83, 0.25);
    }

    &__content {
        height: 100%;
        padding: 18px 26px 34px;
        border: 1px solid var(--color-white-rgba40);
        border-radius: 6px;
    }

    &__el-title {
        margin-bottom: 16px;
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: var(--color-white);
    }

    &__el-text {
        max-width: 350px;
        margin-bottom: 24px;
        color: var(--color-white);
    }

    &__list {
        display: flex;
        gap: 35px;

        @include before_480 {
            flex-direction: column;
        }
    }

    &__water {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    &__icon {
        margin-right: 16px;
    }

    &__water-name {
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 20px;
        color: var(--color-white);
    }

    &__price {
        padding-left: 30px;
        font-size: 24px;
        color: var(--color-white);

        @include before_1200 {
            font-size: 20px;
        }
    }

    &__note {
        color: var(--color-gray-2);

        &--mb40 {
            margin-bottom: 40px;
        }
    }

    &__result {
        font-family: var(--font-secondary);
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        color: var(--color-blue);
        text-align: center;

        @include before_480 {
            font-size: 24px;
            line-height: 32px;
        }
    }
}
