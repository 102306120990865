@import '../../../assets/global-style/mixin';

.cell {
    &--mb10 {
        margin-bottom: 10px;
    }

    &--mb16 {
        margin-bottom: 16px;
    }

    &--mb24 {
        margin-bottom: 24px;
    }

    &__name {
        position: relative;
        display: block;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 24px;
        color: var(--color-gray-3);
        background-color: transparent;
        transition: 0.5s;

        @include before_768 {
            font-size: 14px;
        }
    }

    &__content {
        height: 100%;
        position: relative;
        padding: 18px 24px;
        border: 1px solid var(--color-gray-3);
        border-radius: 8px;
        transition: 0.5s;

        @include before_1024 {
            padding: 10px 12px;
        }
    }

    &__error-message {
        margin-top: 6px;
        font-size: 16px;
        line-height: 140%;
        color: var(--error-primery-color);
        display: none;
    }

    &__identificator {
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translate(0, -50%);
    }

    &__icon {
        display: block;
    }

    &.js-focus {
        .cell__content {
            border-color: var(--color-blue);
        }
    }

    &.js-cell-error {
        .cell__content {
            border-color: var(--error-primery-color);
        }
    }

    &.js-cell-disabled {
        .cell__content {
            background-color: var(--color-gray);
            pointer-events: none;
            cursor: not-allowed;
        }
    }

    &.js-cell-hidden {
        display: none;
    }
}
