@import '../../../assets/global-style/mixin';

.banner {
    background: var(--banner-gradient);

    &__content {
        max-width: 1920px;
        margin: 0 auto;
        padding-bottom: 80px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;

        @include before_1024 {
            padding-bottom: 40px;
            background-size: cover;
        }

        @include before_768 {
            padding-bottom: 16px;
            background-size: cover;
        }
    }

    &__title {
        max-width: 460px;
        font-family: var(--font-secondary);
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 64px;
        line-height: 70px;

        @include before_1024 {
            max-width: 240px;
            font-size: 32px;
            line-height: 35px;
        }

        @include before_480 {
            max-width: 190px;
            font-size: 32px;
            line-height: 35px;
        }
    }

    &__description {
        margin-bottom: 32px;
        font-family: var(--font-secondary);
        font-weight: 300;
        font-size: 36px;
        line-height: 43px;

        @include before_1024 {
            font-size: 18px;
            line-height: 22px;
        }

        @include before_480 {
            max-width: 150px;
        }
    }

    &__button {
        margin-bottom: 24px;

        @include before_768 {
            margin-bottom: 8px;
        }
    }

    &__order {
        position: relative;
        display: flex;
        margin-bottom: 157px;
        padding-left: 80px;

        @include before_1024 {
            margin-bottom: 80px;
        }

        @include before_768 {
            margin-bottom: 24px;
            padding-left: 40px;
        }
    }

    &__arrow-up {
        align-self: flex-start;
        margin-right: 12px;

        @include before_768 {
            width: 16px;
            height: auto;
        }
    }

    &__order-text {
        padding-top: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: var(--color-gray-2);

        @include before_768 {
            padding-top: 4px;
            font-size: 12px;
        }
    }

    &__accreditation {
        font-weight: bold;
        font-size: 20px;
        line-height: 120%;
        color: var(--color-blue);
        // color: black;

        @include before_768 {
            font-size: 12px;
        }
    }

    &__blue {
        @include before_480 {
            color: var(--color-blue);
        }
    }

    &__metrogroup {
        display: block;
        font-weight: bold;
        font-size: 45px;
        line-height: 140%;

        @include before_480 {
            font-size: 24px;
        }
    }
}
